@import url(https://fonts.googleapis.com/css2?family=Tourney:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Audiowide);
@import url(https://fonts.googleapis.com/css2?family=Hind:wght@300;400&family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




.highlightedHead{

    background-color: rgb(56, 45, 45);

}

.test{

    color: aqua;
}



.white{

    color: white;
}

#bod{
  background-color: rgb(240, 234, 227);
}

#projects{

  position: relative;
  top: 100px;


}

.projectName{

    font-size: 40px;
    font-weight: 700;
    color: green;
    grid-column: 2  / 10;
    justify-self: center;
    align-self: center;

}

.projectDiv{
    border-style: solid;
    border-color: black;
    width: 850px;
}

#notice{

    position: relative;
    left: 60%;
}


.fontify{
  font-family: 'Hind', sans-serif;
  font-weight: 300;
}

.fontifyBold{

  font-family: 'Hind', sans-serif;
  font-weight: 600;
}

#introduction{

width: 50%;
  position: relative;

  font-size: 50px;

  border-bottom: double;
  border-color: rgb(223, 198, 198);
  margin-bottom: 20px;

}

.listEle{

  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 7px;
}

.projectList{

  margin-top: 10px;
  padding-left: 15px;

}



.left{
float: left;
width: 300px;

}

.centerText{

  float: center;
  text-align: center;
}

.centerDiv{
  margin: 0 auto;

}


.dropDown{

  width: 55px;
  height: 55px;
  position: relative;
  grid-row: 1 / 2 ;
  align-self: center;
  justify-self: center;

}

#enjoyHomeCare > .dropDown{


  top: -8.3rem;
  right: 17.3rem;
}

.dropDown:hover{
  cursor: pointer;
}

.headerName{

  font-size: 35px;
  font-weight: 400;
  position: relative;
  bottom: 3px;

}



li {

  display: list-item;
  list-style-type: square;
  position: relative;
  left: 10px;
}

#headDiv{

  position: fixed;
  height: 110px;
  background-color: white;
  width: 100%;
  z-index: 3;

}



.projectContainer{

  padding-bottom: 30px;
}




.headerTitle {

font-size: 20px;

}




.headerDiv{

  width: 850px;
  height: 80px;
  border-style: solid;
  border-color: black;
  border-width: 0.05rem;

}

#stockfriend{

  background-color: #4a2a2a;

}

.projectHeader{

  display: grid;
  grid-template-columns: repeat(10, 1fr);

}

#nhl{

  background-color: rgb(193, 187, 187);

}

#enjoyHomeCare{

  background-color: #800020;
  width: 850px;
  height: 90px;

}

/*Headers*/

#MICA{

  color: rgb(8, 8, 83);

}

#nhlHeader{

  font-family:'Tourney', cursive;
  color: red;


}

#stockfriendHeader{

  font-family: "Audiowide", sans-serif;
  color: rgb(81, 243, 81);

  

}

#enjoyhomecarelogo {


  position: relative;
  bottom:4.2rem;
  right: 1.5rem;
  align-self: center;
  height: 13rem;
  width: 14rem;



}


#aboutMeDiv{


  display: grid;
  grid-template-columns: repeat(20, 5rem);
  grid-template-rows: repeat(15, 5rem);


}

#aboutMeStyleDiv{

  background-color: rgb(224, 217, 217);



  grid-row: 2/5;
  grid-column: 1/16;
  border-radius: 30px;
  position: relative;
  right: 5rem;
  z-index: -10;
}

#aboutMeStyleDiv2{

  border-style: double;
  border-color: gray;



  grid-row: 5/13;
  grid-column: 1/17;
  border-radius: 30px;
  position: relative;
  right: 5rem;
  z-index: -15;
  bottom: 1.5rem;
}



#aboutMeText{


  font-size: 1.75rem;
  position: relative;
  bottom: 2rem;
  grid-column: 2/15;
  grid-row: 6/10;

}

#aboutMeInfo{

  grid-column: 7/14;
  grid-row: 3/5;
  font-size: 1.75rem;
  align-self: end;
  position: relative;
  bottom: 2rem;
}

#aboutMeH1{

  font-size: 50px;
  font-weight: 400;
  grid-column: 7/11;
  grid-row: 2/3;
  align-self: end;
  position: relative;
  top: 0.3rem;


}

#profilePicture{

  width: 17rem;
  height: 17rem;
  grid-column: 2 / 6;
  grid-row: 2 / 5;
  border-radius: 4%;
  position: relative;

  align-self: end;
  border: gray;
  border-style: solid;

}




#navLink:hover{

  text-decoration: underline;

}
